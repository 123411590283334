.my-swal {
    z-index: 1400 !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(100%)
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(100%)
}

.main_grid {
    height: 100vh;
}

@media screen and (max-width: 768px) {
    .main_grid {
        /*background: linear-gradient(#49545D, #1C2326);*/
        /*display: none;*/
        margin: 0px;
        padding: 0px;
        background: #292e32;
    }
}

@media screen and (min-width: 768px) {
    .main_grid {
        background: linear-gradient(#49545D, #1C2326);
    }
}

.logo {
    width: 65%;
    padding: 5%;
    margin-left: auto;
    margin-right: auto;
}

.app_bar_logo {

}

/* Hide googlemap close button in Infobox */
/*.gm-style-iw > button {*/
/*    display: none !important;*/
/*}*/

/* InfoBox background */
/*.gm-style .gm-style-iw-d::-webkit-scrollbar-track,*/
/*.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,*/
/*.gm-style .gm-style-iw-c,*/
/*.gm-style .gm-style-iw-t::after,*/
/*.gm-style .gm-style-iw-tc::after {*/
/*    background: black;*/
/*    !*border : solid 1px orange;*!*/
/*}*/